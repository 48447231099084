
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import ReportsService, { ReportsServiceS } from '@/modules/scheduled-reports/reports.service';
import Chocolate from '../components/chocolate.vue';
import CustomLoader from '../components/ui-kit/custom-loader.vue';

@Component({
    components: {
        CustomLoader,
        Chocolate,
    },
})
export default class Unsubscribe extends Vue {
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(ReportsServiceS) private reportsService!: ReportsService;

    isLoggedIn = false;
    status: 'pending' | 'ok' | 'error' = 'pending';

    mounted() {
        const { token } = this.$route.query;
        if (!token) {
            this.status = 'error';
            return;
        }
        this.unsubscribe(token as string);
        this.authService.isLogin().then(res => {
            this.isLoggedIn = res;
        });
    }

    get loginUrl() {
        return this.authService.loginUrl;
    }

    async unsubscribe(token: string) {
        try {
            await this.reportsService.unsubscribeByToken(token);
            this.status = 'ok';
        } catch (err) {
            this.status = 'error';
            throw err;
        }
    }
}
